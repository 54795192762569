import { useEffect } from 'react';
import './App.css';
import contract from './contracts/DegenifyPass.json';
import { useState } from 'react';
import { ethers } from 'ethers';
import { BsDiscord } from "react-icons/bs";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa";
import { Container } from "reactstrap";
import React from "react";


import roo from "./images/roo.gif";




var userAddress = "";
const contractAddress = "0xD56c342aCaC7FaF04c02354411D771B9B76B6c93";
const abi = contract.abi;
var tokenContracts;
var tokenIds;
var counts;
var txArray = [];
var hashArray = [];
var nftOut = 0;
var nftIn = 0;
var hashValue = {};
var internalTx = [];
var OSCounter = 0

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }
    
    const accounts = await ethereum.request({ method: 'eth_accounts'});

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      
    } else{
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
    alert("Please install Metamask!");
    }

    try{
      const accounts = await ethereum.request({ method: 'eth_requestAccounts'});
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }


    const mintNftHandler = async() => { 
      try{
        const { ethereum } = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          
          console.log("Initialize payment");
          let nftTxn = await nftContract.mint(4, { value: ethers.utils.parseEther("0.00")});
          console.log("Mining... please wait");
          await nftTxn.wait();

          console.log('Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}');

        } else {
          console.log("Ethereum object does not exist");
        }
      } catch (err) {
        console.log(err);
      }
    }

    const mintNftHandler2 = async() => { 
      try{
        const { ethereum } = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          
          console.log("Initialize payment");
          let nftTxn = await nftContract.mint(5, { value: ethers.utils.parseEther("0.0045")});
          console.log("Mining... please wait");
          await nftTxn.wait();

          console.log('Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}');

        } else {
          console.log("Ethereum object does not exist");
        }
      } catch (err) {
        console.log(err);
      }
    }

    

     

    
 
  

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Wallet
      </button>
    )
  }

  

  

  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
        MINT 
      </button>
    )
  }
  

  

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundcolor: color,
            height: 1,
            width: 300,

        }}
    />
  );

  useEffect(() => {
    checkWalletIsConnected();
  }, [])
  

  return (
    <div className='main-app'>
    <br></br>
    <Container>
    {connectWalletButton()}<br></br>
    <img src = {roo} width="512" height="512" align="center"></img>
     <h1>Degen Roosters</h1>
     <h2>0% Royalties | No Discord | No "Roadmap"</h2>
     <h2>.0009 per Rooster | 999 Supply</h2>
     {mintNftButton()}
     <h2>Roadmap:</h2>
     <h4>The plan is simple. make this the biggest collection possible.  </h4>
     <h4>airdrop cool sh*t</h4>
     <br></br>

     <h1><a href="https://twitter.com/Degenroo">Twitter</a></h1>
    <Container>
         
   
       </Container>
       </Container>
  </div>
  
)
}


export default App;